/* @flow */

import React from "react";
import { useData } from "crustate/react";
import { HomeData } from "data";
import AdventCalendarDoor from "./AdventCalendarDoor";
import RegisterForm from "./RegisterForm";
import { useTranslate } from "@awardit/react-use-translate";
import type { AdventCalendar as AdventCalendarType } from "shop-state/types";
import cn from "classnames";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

export type HolidayType = "easter" | "christmas";

type Props = {
  doors: AdventCalendarType,
  holidayType?: HolidayType,
};

const AdventCalendar = ({ doors, holidayType }: Props): React$Node => {
  const t = useTranslate();
  const home = useData(HomeData);
  const someDoorOpen = doors.filter(door =>
    door.__typename === "AdventCalendarDoorToday" ||
    door.__typename === "AdventCalendarDoorOpen").length > 0;
  const parentCategoryBanner =
    home.state === "LOADED" &&
    home.data.adventCalendar?.category.clsCatfieldsGeneralImgL !== undefined &&
    home.data.adventCalendar.category.clsCatfieldsGeneralImgL !== null ?
      home.data.adventCalendar.category.clsCatfieldsGeneralImgL : null;

  return (
    <div
      className={cn(styles.adventCalendar, styles[holidayType])}
      style={parentCategoryBanner !== null && holidayType === "christmas" ? { backgroundImage: `url(${parentCategoryBanner.x2 || ""})` } : {}}
    >
      <Wrapper>
        {someDoorOpen ? (
          <>
            <h1 className={styles.adventCalendarTitle}>
              {holidayType === "christmas" ? "Köp dina energismarta klappar inför vintern" : "Öppna dagens Påskägg!"}
            </h1>
            <div className={styles.adventCalendarDoors}>
              {doors.map((d, i) => (
                <AdventCalendarDoor
                  key={i}
                  door={d}
                  holidayType={holidayType}
                  closed={d.__typename === "AdventCalendarDoorLocked"}
                />
              )
              )}
            </div>
          </>
        ) : <RegisterForm paragraph={t("ADVENT_CALENDAR.FORM.SUBSCRIBE_NOW")} />}
      </Wrapper>
    </div>
  );
};

export default AdventCalendar;
